<template>
  <b-card title="Detail Warranty Registration">
    <b-modal
      v-model="loadingWarranty"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center py-3"
      >
        <b-spinner medium variant="primary" label="Spinning"></b-spinner>
        <p class="mt-3">Fetch Warranty Registration Info</p>
      </div>
    </b-modal>

    <b-container class="px-0 mx-0">
      <!-- <b-col cols="12">
          <label>Registration Date</label>
          <b-form-datepicker v-model="warrantyData.createdAt"></b-form-datepicker>
        </b-col>

        <b-col cols="12">
          <label>Warranty Status</label>
          <b-form-input v-model="warrantyData.registerStatus" disabled></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Error Code</label>
          <b-form-input v-model="warrantyErrorMessage" disabled></b-form-input>
        </b-col> -->
      <b-row>
        <b-col cols="12">
          <label>Registration Type</label>
          <b-form-select
            v-model="warrantyData.registerType"
            :options="registerOptions"
          >
          </b-form-select>
        </b-col>
      </b-row>

      <b-row class="section-data">
        <h5>Customer Info</h5>

        <b-col cols="12">
          <label> Type <span style="color:red">(*)</span></label>
          <b-form-select
            v-model="warrantyData.customerType"
            :options="endCostumerTypes"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12">
          <label> Salutation</label>
          <b-form-select
            v-model="warrantyData.salutation"
            :options="listSalutation"
          >
          </b-form-select>
        </b-col>

        <b-col cols="12" v-if="warrantyData.customerType === 'Company'">
          <label>Company Name</label>
          <b-form-input
            v-model="warrantyData.companyName"
            placeholder="Company Name"
          ></b-form-input>
        </b-col>

        <b-col cols="12" v-if="warrantyData.customerType === 'Individual'">
          <label>First Name</label>
          <b-form-input
            v-model="warrantyData.nameFirst"
            placeholder="Customer Name"
          ></b-form-input>
        </b-col>

        <b-col cols="12" v-if="warrantyData.customerType === 'Individual'">
          <label>Last Name</label>
          <b-form-input
            v-model="warrantyData.nameLast"
            placeholder="Customer Name"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Contact Number</label>
          <b-form-input
            v-model="warrantyData.phone"
            placeholder="Customer Phone"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Email <span style="color:red">(*)</span></label>
          <b-form-input
            v-model="warrantyData.email"
            placeholder="Email"
          ></b-form-input>
        </b-col>
      </b-row>

      <b-row class="section-data">
        <h5>Customer Address</h5>

        <b-col cols="12">
          <label>Postal Code</label>
          <b-row>
            <b-col md="9" cols="12">
              <b-form-input
                v-model="warrantyData.postal"
                type="text"
                maxlength="6"
                placeholder="Enter Postal"
              ></b-form-input>
            </b-col>
            <b-col md="3" cols="12">
              <b-button
                @click="addAddressByPostal(warrantyData.postal)"
                variant="success"
                class="mr-1"
                >Auto Fill Address
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12">
          <label>Block No</label>
          <div v-if="isBuildingNoArray">
            <b-form-select
              :options="arrayBuildingNo"
              v-model="warrantyData.address"
              placeholder="Customer Address"
            ></b-form-select>
          </div>
          <div v-else>
            <b-form-input
              v-model="warrantyData.address"
              placeholder="Input postal to autofill"
            ></b-form-input>
          </div>
        </b-col>

        <b-col cols="12">
          <label>Road Name</label>
          <div v-if="isStreetArray">
            <b-form-select
              :options="arrayStreet"
              v-model="warrantyData.address2"
              placeholder="Customer Address"
            ></b-form-select>
          </div>
          <div v-else>
            <b-form-input
              v-model="warrantyData.address2"
              placeholder="Input postal to autofill"
            ></b-form-input>
          </div>
        </b-col>

        <b-col cols="12">
          <label>Unit No</label>
          <b-form-input
            v-model="warrantyData.address3"
            placeholder="Customer Address"
          ></b-form-input>
        </b-col>

        <b-col cols="12">
          <label>Building Name</label>
          <div v-if="isBuildingNameArray">
            <b-form-select
              :options="arrayBuildingName"
              v-model="warrantyData.buildingName"
              placeholder="Customer Address"
            ></b-form-select>
          </div>
          <div v-else>
            <b-form-input
              v-model="warrantyData.buildingName"
              placeholder="Input building name"
            ></b-form-input>
          </div>
        </b-col>
        <b-col cols="12">
          <label>Building type</label>
          <b-form-select
            v-model="warrantyData.buildingType"
            placeholder="select Building Type"
            :options="buildingTypeOpt"
          >
          </b-form-select>
        </b-col>
      </b-row>

      <b-row class="section-data">
        <h5>
          {{
            warrantyData.registerType === "airpur"
              ? "Purchase Info"
              : "Installation Info"
          }}
        </h5>

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installer Name</label>
          <b-form-input
            v-model="warrantyData.installerName"
            placeholder="Installer Name"
          ></b-form-input>
        </b-col>

        <b-col cols="12" class="hidden">
          <label>Installer Address</label>
          <b-form-input
            v-model="warrantyData.installerAddress"
            placeholder="Installer Address"
          ></b-form-input>
        </b-col>

        <b-col cols="12" v-if="warrantyData.registerType === 'airpur'">
          <label>Purchase Date <span style="color:red">(*)</span></label>
          <b-form-datepicker
            :max="max"
            v-model="warrantyData.purchaseDate"
          ></b-form-datepicker>
        </b-col>

        <b-col cols="12" v-if="warrantyData.registerType === 'aircon'">
          <label>Installation Date <span style="color:red">(*)</span></label>
          <b-form-datepicker
            :max="max"
            v-model="warrantyData.installationDate"
          ></b-form-datepicker>
        </b-col>
      </b-row>

      <b-row class="section-data">
        <h5>Promo Code</h5>
        <b-col cols="12">
          <label>Reference Code</label>
          <b-form-input
            v-model="warrantyData.promoCode"
            placeholder="Reference Code"
          ></b-form-input>
        </b-col>
        <!-- <b-col cols="12">
          <label>P Code</label>
          <b-form-input v-model="warrantyData.pCode" placeholder="P Code"></b-form-input>
        </b-col> -->
      </b-row>

      <div class="section-data">
        <h5>Invoice</h5>

        <!-- <validation-provider
                #default="{ errors }"
                name="Invoice Date"
                rules="required"
                tag="div"
                class="form-line"
              > -->
        <label>Invoice Date</label>

        <div class="form-validation-input">
          <b-form-datepicker
            :max="max"
            placeholder="Select Invoice date"
            v-model="warrantyData.invoiceDate"
          ></b-form-datepicker>
        </div>
        <!-- </validation-provider> -->

        <!-- TODO: ADD upload filed -->
        <b-modal size="xl" hide-footer v-model="showImage">
          <div
            class="py-2"
            style="display: flex; justify-content: center; align-content: center;"
          >
            <img
              style="cursor: pointer; max-width: 1280px; max-height: 720px; height: auto; width: auto;"
              @click="showImage = true"
              :src="previewImage"
            />
          </div>
        </b-modal>
        <div class="form-line">
          <label>Select Invoice</label>

          <b-form-file
            id="id1"
            @change="uploadInvoiceImage()"
            v-model="selected"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept="image/jpeg, image/jpg, image/gif, image/gif, application/pdf"
          />

          <!-- <b-form-file v-model="selected" class="w-full mb-1" />
                <b-button @click="coba">ccc</b-button> -->
        </div>
        <div style="margin: 10px;">
          <div
            style="display: flex; flex-direction: column; align-items: center; "
          >
            <img
              class="mt-1"
              style="cursor: pointer;"
              @click="showImage = true"
              width="30%"
              height="30%"
              v-if="isImage"
              :src="previewImage"
            />
            <div
              v-else-if="isPdf"
              style="position: relative; overflow: auto; width: 100%;"
            >
              <iframe
                :src="previewPdf"
                type="application/pdf"
                width="100%"
                height="425"
              ></iframe>
              <b-button class="align-center" @click="openPdfInNewTab"
                >open in new tab
              </b-button>
            </div>
          </div>
          <p style="color: red; margin-top: 4px" v-if="isValidImage">
            {{ isValidMessage }}
          </p>
        </div>
      </div>

      <b-col cols="12" class="section-data my-2">
        <h5>Product Entry</h5>

        <b-form-group label="Item Model">
          <b-form-input
            v-model="model"
            :disabled="warrantyData.selectedDo.length > 0"
            placeholder="Input Item Model"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Serial">
          <b-form-input
            v-model="serial"
            :disabled="warrantyData.selectedDo.length > 0"
            placeholder="Input Serial Number"
          >
          </b-form-input>
        </b-form-group>

        <div class="my-1"><Span>OR</Span><br /></div>

        <b-form-group label="Item Barcode">
          <b-form-input
            v-model="barcode"
            placeholder="Input Item Barcode"
            maxLength="14"
            :disabled="warrantyData.selectedDo.length > 0"
          >
          </b-form-input>
        </b-form-group>

        <div class="my-1"><Span>OR</Span><br /></div>
        <b-form-group label="Delivery Order Number">
          <b-form-input
            v-model="sapDo"
            placeholder="Input DO Number"
            :disabled="nonDOItems.length > 0"
          >
          </b-form-input>
        </b-form-group>
        <b-button
          variant="outline-primary"
          @click="manualAddItem"
          :disabled="isLoading"
        >
          Add Item
        </b-button>
      </b-col>

      <b-col
        cols="12"
        class="my-2 section-data"
        v-if="warrantyData.selectedDo.length !== 0"
      >
        <h5>DO Selected List</h5>
        <b-table :items="warrantyData.selectedDo" :fields="doFields" show-empty>
          <template #cell(customer)="row">
            {{ row.item.CustomerName1 }} {{ row.item.CustomerName2 }}
          </template>
          <template #cell(action)="row">
            <b-button
              variant="danger"
              size="sm"
              @click="removeItem(row.item)"
              class="mr-1"
            >
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12" class="my-2 section-data">
        <h5>Product to be registered</h5>

        <b-table :items="warrantyData.items" :fields="fields" responsive>
          <template #cell(generalWarrantyPeriodEnd)="row">
            {{ row.item.generalWarrantyPeriodEnd | formatDate }}
          </template>

          <template #cell(compressorWarrantyPeriodEnd)="row">
            {{ row.item.compressorWarrantyPeriodEnd | formatDate }}
          </template>

          <template #cell(actions)="row">
            <div class="d-flex">
              <b-button
                variant="danger"
                v-if="warrantyData.selectedDo.length === 0"
                size="sm"
                @click="doDeleteItemV2(row)"
                class="mr-1"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>

              <!-- <b-button variant="danger" size="sm" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="resetItemStatus(row.item._id)">
                  <b-icon icon="arrow-clockwise"></b-icon>
                </b-button> -->
            </div>
          </template>
        </b-table>
      </b-col>

      <div class="mt-6 mb-6 d-flex flex-row-reverse">
        <!-- <b-button class="ml-1" variant="danger" :disabled="!(warrantyData.registerStatus !== 'Registered')" @click="deletePaperlessWarranty">Delete</b-button> -->
        <b-button
          variant="success"
          :disabled="
            !(warrantyData.registerStatus !== 'Registered') || onSubmit
          "
          @click="process"
        >
          <template v-if="onSubmit">
            <div class="d-flex justify-content-center align-items-center">
              <b-spinner label="Loading..." class="mr-1"></b-spinner> Processing
            </div>
          </template>

          <template v-else>
            Process
          </template>
        </b-button>
      </div>
    </b-container>
    <b-modal hide-footer v-model="deleteModal">
      <p>Please confirm you want to delete this item?</p>

      <b-button class="mt-2 mr-1" @click="doDeleteItemV2">Yes</b-button>
      <b-button class="mt-2" @click="deleteModal = false">No</b-button>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "@/axios";
import { sleep } from "@/utils/utils.js";
import lodash from "lodash";
export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    return {
      max: maxDate,
      fields: [
        { key: "itemModel", label: "Model" },
        { key: "serial", label: "Serial" },
        { key: "barcode", label: "Barcode" },
        { key: "sapDo", label: "DO" },
        { key: "warrantyStatus", label: "Warrany Status" },
        { key: "generalWarrantyPeriodEnd", label: "General Warranty End" },
        {
          key: "compressorWarrantyPeriodEnd",
          label: "Compressor Warranty End",
        },
        { key: "actions", label: "Actions" },
      ],
      endCostumerTypes: [
        { value: "Company", text: "Company" },
        { value: "Individual", text: "Individual" },
      ],
      arrayBuildingNo: [],
      arrayStreet: [],
      arrayBuildingName: [],
      isBuildingNoArray: false,
      isStreetArray: false,
      isBuildingNameArray: false,
      warrantyData: {
        selectedDo: [],
        address: "",
        address2: "",
        address3: "",
        buildingName: "",
        buildingType: "",
        installationDate: "",
        registerType: "aircon",
        items: [],
        purchaseDate: "",
        invoiceDate: "",
        fileInvoice: null,
        customerType: "",
      },
      items: [],
      selectedItem: null,
      registerOptions: [
        { value: "aircon", text: "Air Conditioner" },
        { value: "airpur", text: "Air Purifier" },
      ],
      deleteModal: false,
      idTodelete: null,
      indexToDelete: 0,
      listSalutation: [
        { value: "MR", text: "Mr." },
        { value: "MS", text: "Ms." },
      ],
      model: null,
      serial: null,
      barcode: null,
      sapDo: null,
      onSubmit: false,
      loadingWarranty: false,
      buildingTypeOpt: [
        { value: "", text: "Select Building Type", disabled: true },
        "HDB",
        "Condominium",
        "Private Housing",
        "Commercial Building",
      ],
      previewImage: "",
      showImage: false,
      isValidImage: null,
      isValidMessage: "",
      selected: null,
      image: "",
      isLoading: false,
      isImage: false,
      isPdf: false,
      doFields: [
        { key: "sapDo", label: "DO Number" },
        { key: "sapSo", label: "SO Number" },
        { key: "dealerNo", label: "Dealer Code" },
        { key: "action", label: "Action" },
      ],
      nonDOItems: [],
    };
  },
  mounted() {},
  computed: {
    isAirPurified() {
      let flag =
        (this.warrantyData.purchaseDate !== undefined) &
        (this.warrantyData.purchaseDate !== null);
      if (flag) {
        return true;
      }

      return false;
    },
    warrantyErrorMessage: {
      get() {
        let { errorCode, errorStatus } = this.warrantyData;

        if (errorCode !== undefined || errorStatus !== undefined) {
          return `${errorCode} - ${errorStatus}`;
        }

        return ``;
      },
      set(val) {},
    },
  },
  methods: {
    ...mapActions({
      getAddressByPostal: "mapAPI/getAddressByPostal",
      createWarranty: "warranty/createWarranty",
      deleteWarrantyItemId: "warranty/deleteExternalWarrantyItem",
      deleteExternalWarranty: "warranty/deleteExternalWarranty",
      getUsersBy: "auth/getUsersBy",
      addNewUser: "auth/newUserRandomPW",
      verifyDo: "projectWarranty/verifyDo",
    }),
    async addAddressByPostal(postal) {
      if (postal.length != 6) {
        this.$bvToast.toast(`Postal not valid`, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var address;
      try {
        address = await this.getAddressByPostal(postal);
      } catch (err) {
        this.$bvToast.toast(
          `We are experiencing issue with OneMap API to retrieve your address, please enter your address manually.`,
          {
            title: "Failed",
            variant: "danger",
            solid: true,
          }
        );
        return;
      }
      console.info("address ", address);

      if (address.length <= 0) {
        this.$bvToast.toast("Postal " + postal + " has no address", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      var arrayStreet = [];
      var arrayBuildingName = [];
      var arrayBlockNumber = [];
      for (let postalData of address) {
        let { ROAD_NAME, BLK_NO, BUILDING } = postalData;
        arrayStreet.push(ROAD_NAME);
        arrayBuildingName.push(BUILDING);
        arrayBlockNumber.push(BLK_NO);
      }
      let newSetArrayStreet = [...new Set(arrayStreet)];
      let newSetArrayBuildingName = [...new Set(arrayBuildingName)];
      let newSetArrayBlockNumber = [...new Set(arrayBlockNumber)];

      if (newSetArrayBlockNumber.length > 1) {
        this.arrayBuildingNo = newSetArrayBlockNumber;
        this.isBuildingNoArray = true;
      } else {
        this.warrantyData.address = newSetArrayBlockNumber[0];
        this.isBuildingNoArray = false;
      }

      if (newSetArrayStreet.length > 1) {
        this.arrayStreet = newSetArrayStreet;
        this.isStreetArray = true;
      } else {
        this.warrantyData.address2 = newSetArrayStreet[0];
        this.isStreetArray = false;
      }

      if (newSetArrayBuildingName.length > 1) {
        this.arrayBuildingName = newSetArrayBuildingName;
        this.isBuildingNameArray = true;
      } else {
        this.warrantyData.buildingName = newSetArrayBuildingName[0];
        this.isBuildingNameArray = false;
      }
      this.$bvToast.toast(`Address found`, {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },
    removeItem(item) {
      this.warrantyData.selectedDo.splice(item, 1);
      this.warrantyData.items = this.warrantyData.items.filter(
        (x) => x.sapDo !== item.sapDo
      );
    },
    async fillDo() {
      const sameDO = this.warrantyData.selectedDo.some(
        (x) => x.sapDo === this.sapDo
      );

      if (sameDO) {
        this.$bvToast.toast("Cannot add same DO Number", {
          title: "DO number has been added to the list",
          variant: "danger",
          solid: true,
        });
        this.sapDo = "";
        return false;
      }

      try {
        let res = await this.verifyDo({ sapDo: this.sapDo });

        this.warrantyData.selectedDo.push({
          sapDo: res.sapDo,
          sapSo: res.sapSo,
          pcode: res.pcode,
          dealerNo: res.dealerNo,
        });

        this.$bvToast.toast("Delivery Order Found", {
          title: "Success",
          variant: "success",
          solid: true,
        });

        return true;
      } catch (e) {
        console.log({ e });
        this.$bvToast.toast(e.response.data.errors[0].message, {
          title: "Failed",
          variant: "danger",
          solid: true,
        });

        this.sapDo = "";
        return false;
      }
    },

    postImages() {
      console.log(this.selected);
      const fd = new FormData();
      fd.append("image", this.selected, this.selected.name);

      axios
        .post("sales-and-purchase/drcc/upload-image/invoice", fd)
        .then((res) => {
          this.$bvToast.toast("Image Uploaded Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.warrantyData.fileInvoice = res.data.data;

          // console.log("image RSP ", this.warrantyData.fileInvoice);
          // console.log(res);
        })
        .catch((err) => {
          console.log({ err });
          this.$bvToast.toast("Failed To Upload Image", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
        });
    },
    // uploadInvoiceImage() {
    //   console.log('selected',this.selected);
    //   const type = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg']
    //   this.isValidImage = false;
    //   this.isValidMessage = '';
    //   const file = document.querySelector("input[id=id1]").files[0];
    //   console.log({file})
    //   const reader = new FileReader();

    //   let rawImg;
    //   reader.onloadend = () => {
    //     rawImg = reader.result;
    //     this.previewInvoice = rawImg;
    //   };
    //   reader.readAsDataURL(file);
    //   this.selected = file;

    //   if (this.selected.size > 1000000) {
    //     this.isValidImage = true;
    //     this.isValidMessage = 'File size should be less than 1 MB';
    //   }

    //   if (!type.includes(this.selected.type)) {
    //     this.isValidImage = true;
    //     this.isValidMessage = 'Input should be a format image';
    //   }

    // },
    async uploadInvoiceImage() {
      // console.log('selected',this.selected);

      this.isImage = false;
      this.isPdf = false;
      this.isValidMessage = "";

      const type = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/jpg",
        "application/pdf",
      ];
      const file = document.querySelector("input[id=id1]").files[0];

      if (!file || !file.name) {
        // No file selected
        return;
      }

      const reader = new FileReader();
      const readAsDataURL = () => {
        return new Promise((resolve) => {
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(file);
        });
      };

      try {
        const result = await readAsDataURL();

        if (type.includes(file.type)) {
          if (file.type.startsWith("image/")) {
            this.previewImage = result;
            this.isImage = true;
          } else if (file.type === "application/pdf") {
            this.previewPdf = result;
            this.isPdf = true;
          }
        }
      } catch (error) {
        console.error("Error reading file:", error);
      }
      if (file.size > 1000000) {
        this.isValidMessage = "File size should be less than 1 MB";
        return;
      }

      if (!type.includes(file.type)) {
        this.isValidMessage = "Input should be an image or PDF file";
        return;
      }
    },
    openPdfInNewTab() {
      console.log("click");
      // Optionally, open the PDF in a new tab
      const pdfWindow = window.open();
      pdfWindow.document.write(
        '<iframe width="100%" height="100%" src="' +
          this.previewPdf +
          '"></iframe>'
      );
    },
    async process() {
      let resImg;
      let resImgError = false;
      if (this.onSubmit) {
        console.log("Still Processing Item");
        // Just Prevent Processing Logic To Continue
        return;
      }
      // if (this.selected == null ) {
      //   // Prevent invoice file from null
      //   this.$bvToast.toast('Invoice File is required', {
      //     title: "Failed",
      //     variant: "danger",
      //     solid: true,
      //   });
      //   return;
      // }else {}

      if (this.selected !== null) {
        const fd = new FormData();
        fd.append("image", this.selected, this.selected.name);

        try {
          resImg = await axios.post(
            "sales-and-purchase/drcc/upload-image/invoice",
            fd
          );
          console.log("res Axios", resImg);
          this.warrantyData.fileInvoice = resImg.data.data;
        } catch (error) {
          resImgError = true;
          console.error(error);
        }
      }

      let rawData = JSON.stringify(this.warrantyData);
      let data = JSON.parse(rawData);

      if (data.registerType === "airpur") {
        data.installerName = "";
        data.installationDate = "";
      } else {
        data.purchaseDate = "";
      }

      if (data.customerType === "Individual") {
        data.companyName = "";
        if (
          this.warrantyData.nameFirst === "" ||
          this.warrantyData.nameFirst == undefined
        ) {
          this.$bvToast.toast("First Name is required", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }
        if (
          this.warrantyData.nameLast === "" ||
          this.warrantyData.nameLast == undefined
        ) {
          this.$bvToast.toast("Last Name is required", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }
      }

      if (data.customerType === "Company") {
        data.nameFirst = "";
        data.nameLast = "";
        if (
          this.warrantyData.companyName === "" ||
          this.warrantyData.companyName == undefined
        ) {
          this.$bvToast.toast("Company Name is required", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return;
        }
      }

      if (data.registerType === "airpur" && data.purchaseDate === "") {
        this.$bvToast.toast("Purchase Date is required", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      if (data.registerType === "aircon" && data.installationDate === "") {
        this.$bvToast.toast("Installation Date is required", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }
      // Prevent Backend Filled With Invalid Data
      if (this.warrantyData.registerType === "airpur") {
        // Air Purifier Don't Have Any Installer Info
        data.installerName = "";
        data.installationDate = "";
      } else {
        // Air Conditioner Don't Have Purchase Date
        data.purchaseDate = "";
      }

      if (data.items.length === 0) {
        // Prevent Zero Products Registration
        this.$bvToast.toast("No Products To Register", {
          title: "Failed",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.onSubmit = true;

      if (resImgError == true) {
        this.onSubmit = false;
        return this.$bvToast.toast(
          "Upload Image Error. Please check the extension type file.",
          {
            title: "Failed",
            variant: "danger",
            solid: true,
          }
        );
      }
      // let accountId = null;
      // let isUserExists = await this.getUsersBy({ email: this.warrantyData.email });

      // if (isUserExists.length > 0) {
      //   accountId = isUserExists[0].id
      // } else {
      //   let fullname = `${this.warrantyData.nameFirst} ${this.warrantyData.nameLast}`
      //   let customerData = {
      //     name: fullname,
      //     role:'Customer',
      //     email:this.warrantyData.email,
      //   }
      //   let newUserData = await this.addNewUser(customerData);
      //   accountId = newUserData.id;
      // }

      // if (accountId !== null) {
      return this.createWarranty(data)
        .then(async (createdWarranty) => {
          this.$bvToast.toast("Successfully Process Paperless Warranty", {
            title: "Success",
            variant: "success",
            solid: true,
          });

          this.onSubmit = false;
          await sleep(1000);
          this.$router.go(-1);
        })
        .catch((e) => {
          console.log({ e });
          this.$bvToast.toast(e.response.data.errors[0].message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          });

          this.onSubmit = false;
        });
      // } else {
      //   console.log('For Some Reason It\'s Null !');
      //   this.onSubmit = false;
      // }
    },
    // getWarrantyData() {
    //   axios.get(`sales-and-purchase/drcc/external-warranty/detail/${this.$route.params.id}`).then((response) => {
    //     this.warrantyData = response.data.data;
    //     this.loadingWarranty = false;
    //   }).catch((e) => {
    //     console.log(e)
    //   })
    // },
    getAllItems() {
      axios
        .get(`sales-and-purchase/drcc/postal/non-order-items`)
        .then((response) => {
          this.nonSaleItems = response.data.data.map((item) => {
            return Object.assign(
              {},
              { value: item, text: `${item.itemModel} - ${item.barcode}` }
            );
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    addItem() {
      this.warrantyData.items.push(this.selectedItem);
    },
    deleteItem(row) {
      this.warrantyData.items.splice(row.index, 1);
    },
    confirmToDeleteItem(row) {
      this.deleteModal = true;
      this.idTodelete = row.item._id;
      this.indexToDelete = row.index;
    },
    // deletePaperlessWarranty() {
    //   if (confirm('Please confirm you want to delete this draft warranty? ')) {
    //     this.deleteExternalWarranty(this.warrantyData._id).then((result) => {
    //       if (result) {
    //         this.$router.go(-1);
    //       }
    //     });
    //   }
    // },
    doDeleteItemV2(row) {
      this.warrantyData.items.splice(row.index, 1);
      this.nonDOItems.splice(row.index, 1);
    },
    // doDeleteItem() {
    //   this.deleteWarrantyItemId({
    //     id: this.idTodelete,
    //     warrantyId: this.warrantyData._id,
    //   }).then(() => {
    //     this.warrantyData.items.splice(this.indexToDelete, 1);
    //     // this.getWarrantyData();
    //     this.deleteModal = false;
    //   }).catch((e) => {
    //     this.deleteModal = false;
    //   })
    // },
    mergeUniqueByBarcode(data, incoming) {
      const merged = [...data, ...incoming];
      const uniqueById = merged.reduce((acc, item) => {
        if (!acc.some((entry) => entry.barcode === item.barcode)) {
          acc.push(item);
        }
        return acc;
      }, []);
      return uniqueById;
    },
    async manualAddItem() {
      if (
        this.barcode == null &&
        this.serial == null &&
        this.model == null &&
        this.sapDo == null
      ) {
        this.$bvToast.toast(
          "Failed, please input serial or barcode or delivery order number",
          {
            title: "Fail",
            variant: "danger",
            solid: true,
          }
        );
        return;
      }
      if (this.warrantyData.items.length > 200) {
        this.$bvToast.toast("Input Failed, item list exceed 200", {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
        return;
      }
      // const sameSerial = this.warrantyData.items.some(x => x.serial === this.serial)
      // const sameModel= this.warrantyData.items.some(x => x.itemModel=== this.model)
      // const sameBarcode= this.warrantyData.items.some(x => x.barcode=== this.barcode)
      this.isLoading = true;

      /** New DO field. accept DO, verify the DO then continue if verify success */
      if (this.sapDo !== null) {
        if (this.sapDo !== "") {
          let checkDO = await this.fillDo();
          if (checkDO == false) {
            this.isLoading = false; // Stop loading if fillDo fails
            return;
          }
        } else {
          this.isLoading = false; // Stop loading if sapDo is empty
          return;
        }
      }
      axios
        .post(`sales-and-purchase/drcc/warranty/admin-verify`, {
          registerType: this.warrantyData.registerType,
          barcode: this.barcode,
          serial: this.serial,
          model: this.model,
          sapDo: this.sapDo,
        })
        .then((response) => {
          this.isLoading = false;

          let item = response.data.data;

          if (Array.isArray(item)) {
            let existing = this.warrantyData.items;
            let incoming = item;
            let mergeResult = this.mergeUniqueByBarcode(existing, incoming);

            if (mergeResult !== undefined) {
              this.warrantyData.items = mergeResult;
            }
            // for(const it of item){
            //   this.warrantyData.items.push(it)
            // }
            this.$bvToast.toast("Success Verify DO Number", {
              title: "Success",
              variant: "success",
              solid: true,
            });
            this.model = null;
            this.serial = null;
            this.barcode = null;
            this.sapDo = null;
          } else {
            let itemDuplicate = this.warrantyData.items.filter((x) => {
              if (x.barcode == item.barcode) {
                return true;
              }
              return false;
            });
            if (itemDuplicate.length > 0) {
              this.$bvToast.toast("Item Already Exists", {
                title: "failed",
                variant: "danger",
                solid: true,
              });

              this.barcode = null;
              this.model = null;
              this.serial = null;
              this.sapDo = null;
              return;
            }
            this.warrantyData.items.push(response.data.data);
            this.nonDOItems.push(response.data.data);

            // this.items.push(response.data.data);
            this.$bvToast.toast("Success Verify Item", {
              title: "Success",
              variant: "success",
              solid: true,
            });

            this.barcode = null;
            this.model = null;
            this.serial = null;
          }
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
          if (e.request.status === 400) {
            this.$bvToast.toast(`${e.response.data.errors[0].message}`, {
              title: "Failed",
              variant: "danger",
              solid: true,
              autoHideDelay: 6000,
            });
          } else {
            this.$bvToast.toast(`${e.response.data.message}`, {
              title: "Fail",
              variant: "danger",
              solid: true,
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.section-data {
  padding: 15px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: relative;
  margin: 20px 0 25px 0;
}

.section-data h5 {
  position: absolute;
  top: -10px;
  left: 6px;
  background: white;
  padding: 0 10px;
}
</style>
